import { submitApplicationApi } from "../api/submitApplication"
import { paymentDetailsApi } from "../api/paymentDetails"
import { applicationFormsListApi } from "../api/applicationFormsList"
import { cancelPaymentApi } from "../api/cancelPayment"

export default {
    submitApplicationApi,
    paymentDetailsApi,
    applicationFormsListApi,
    cancelPaymentApi
};
