import { action } from "typesafe-actions";
import { RESEND_RECEIPT_ACTION, RETRIEVE_RECEIPT_DATA_ACTION, RETRIEVE_RECEIPT_DATA_SUCCESS_ACTION, ERROR, SHOW_SPINNER } from "./constants";
import { CompletionModel, ResponseModel, CompleteError } from "./model";

export const retrieveReceiptDataAction = (paymentReference: string) =>
    action(RETRIEVE_RECEIPT_DATA_ACTION, paymentReference);

export const retrieveReceiptDataSuccessAction = (model: ResponseModel) =>
    action(RETRIEVE_RECEIPT_DATA_SUCCESS_ACTION, model);

export const resendReceiptAction = (model: CompletionModel) =>
    action(RESEND_RECEIPT_ACTION, model);

export function errorAction(error: CompleteError) {
    return action(ERROR, error);
}

export const showSpinnerAction = (show: boolean) =>
    action(SHOW_SPINNER, show);

