import * as React from "react";
import {useRef, useEffect, useState} from "react";
//import "@servicensw/form/index.scss"

import { addSize, Form, Props, getFieldNameError } from "./Form"
type SelectProps = Props & {
    setFocus?: boolean;
    setStepFocus?: boolean;
}
export const Select = ({
    validationMessage,
    size = "",
    fieldName,
    children,
    value,
    setValue,
    setFocus = false,
    setStepFocus = false,
    ...other
}: SelectProps) => {
    const fieldNameError = getFieldNameError(fieldName);
    const error = validationMessage ? "error" : "";
    const selectInput = useRef<HTMLSelectElement | null>(null);
    const [focusVisibleClass, setFocusVisibleClass] = useState("");
    useEffect(() => {
        if (selectInput && setFocus) {
            selectInput!.current!.focus();
            setFocusVisibleClass("focus-visible");
        }
    }, [setFocus]);
    useEffect(() => {
        if (selectInput && setStepFocus) {
            selectInput!.current!.focus();
            setFocusVisibleClass("focus-visible");
        }
    }, [setStepFocus]);

    const fieldType = <select id={fieldName} className={`form__select ${error} ${addSize(size)} ${focusVisibleClass}`} value={value} ref={selectInput}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setValue(e.target.value)} onBlur={() => setFocusVisibleClass("")} aria-describedby={`${fieldNameError} validationErrorSummary`}>
        {children}
    </select>;

    return (<Form fieldType={fieldType} fieldName={fieldName} validationMessage={validationMessage} {...other} />);
};