// ReSharper disable InconsistentNaming
export const GET_HELLO_WORLD = "GET_HELLO_WORLD";
export const DISPLAY_HELLO_WORLD = "DISPLAY_HELLO_WORLD";
export const VALIDATE_HOME_FORM = "VALIDATE_HOME_FORM";
export const CHECK_HOME_REDIRECT = "CHECK_HOME_REDIRECT";
//todo: check duplicated use of "ERROR" constant
export const HOME_UPDATE_DETAIL_ACTION = "HOME_UPDATE_DETAIL_ACTION";
export const HOME_UPDATE_AMOUNT_FOR_APPLICATION_ACTION = "HOME_UPDATE_AMOUNT_FOR_APPLICATION_ACTION";
export const HOME_INITIALISE_DROP_DOWNS_ACTION = "HOME_INITIALISE_DROP_DOWNS_ACTION";
export const HOME_INITIALISE_DROP_DOWNS_SUCCESS_ACTION = "HOME_INITIALISE_DROP_DOWNS_SUCCESS_ACTION";
export const HOME_INITIALISE_DROP_DOWNS_ERROR_ACTION = "HOME_INITIALISE_DROP_DOWNS_ERROR_ACTION";
export const HOME_INITIALISE_FORM_FEE_ACTION = "HOME_INITIALISE_FORM_FEE_ACTION";
export const RESET_AMOUNT_FOR_APPLICATION_UPDATE_ACTION_SUCCESS_ACTION = "RESET_AMOUNT_FOR_APPLICATION_UPDATE_ACTION_SUCCESS_ACTION";
export const AGENCY_RESET_ACTION_SUCCESS_ACTION = "AGENCY_RESET_ACTION_SUCCESS_ACTION";

export const PAYER_DETAIL_UPDATE_ACTION = "PAYER_DETAIL_UPDATE_ACTION";
export const PAYER_ERROR = "PAYER_ERROR";
export const SHOW_SPINNER = "SHOW_SPINNER";
export const GET_OAUTH_TOKEN = "GET_OAUTH_TOKEN";
export const SUBMIT_PAYMENT = "SUBMIT_PAYMENT";
export const SUBMIT_PAYMENT_COMPLETE = "SUBMIT_PAYMENT_COMPLETE";
export const VALIDATE_PAYERDETAILS_FORM = "VALIDATE_PAYERDETAILS_FORM";
// ReSharper restore InconsistentNaming
