import Types from "AppTypes";
import { RESEND_RECEIPT_ACTION, RETRIEVE_RECEIPT_DATA_ACTION, RETRIEVE_RECEIPT_DATA_SUCCESS_ACTION, ERROR } from "./constants";
import { CompletionModel, initialState } from "./model";

export const completionReducer = (state: CompletionModel = initialState, action: Types.RootAction): CompletionModel => {
    switch (action.type) {
        case RESEND_RECEIPT_ACTION:
            return {
                ...state, ...action.payload
            };
        case RETRIEVE_RECEIPT_DATA_ACTION:
            return {
                ...state,
                needsContext: false
            };
        case RETRIEVE_RECEIPT_DATA_SUCCESS_ACTION:
            return {
                ...state,
                ...action.payload,
                correlationId: action.payload.paymentReference,
                receiptId: action.payload.bankReference,
                invoiceToName: action.payload.payerFullName,
                applicationAmount: action.payload.amount,
                surcharge: action.payload.surcharge + action.payload.surchargeGst,
                totalAmount: action.payload.amount + action.payload.surcharge + action.payload.surchargeGst,
                invoiceToEmail: action.payload.payerEmailAddress,
                date: action.payload.completedAt + "Z", // Indicate UTC
                spinner: false,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
                isErrored: true
            };
        default:
            return state;
    }
};
