import * as React from "react";
import "@servicensw/navigation/index.scss";

import { NavProps } from "./Navigation";

type Props = NavProps & {
    navPrevious?: boolean;
    navBack?: boolean;
};

export const Breadcrumb = ({
    label,
    onClick,
    href="#",
    navPrevious = false,
    navBack = false
}: Props) => {
    const previous = () => navPrevious ? "breadcrumb__previous" : "";
    const back = () => navBack ? "breadcrumb__back" : "";

    return (
        <li className={`${previous()} ${back()}`}><a href={href} onClick={onClick}>{label}</a></li>
    );
}