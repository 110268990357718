// Models.
export type CompletionModel = {
    needsContext: boolean,
    correlationId: string,
    receiptId: string,
    date: string,
    invoiceToName: string,
    applicationAmount: number,
    processingFee: number,
    compensationFundContribution: number,
    surcharge: number,
    totalAmount: number,
    invoiceToEmail: string,
    spinner: boolean,
    error: CompleteError | undefined,
    isErrored: boolean,
    productDescription: string,
    paymentMethod: string,
    cardType: string,
    cardLast4Digits: string,
    totalAmountIncludesGst: boolean,
}

export type CompleteError = {
    returnType: number,
    returnMessage: string | undefined,
}

export type ResponseModel = {
    paymentReference: string,
    bankReference: string,
    applicationNumber: string,
    firstName: string,
    middleName: string,
    lastName: string,
    companyName: string,
    additionalDetails: string,
    payerFullName: string,
    amount: number,
    surcharge: number,
    surchargeGst: number,
    payerEmailAddress: string,
    completedAt: string,
    paymentMethod: string,
    cardType: string,
    cardLast4Digits: string,
    totalAmountIncludesGst: boolean,
}

export const initialState = {
    needsContext: true,
    correlationId: '',
    receiptId: '',
    date: '',
    invoiceToName: '',
    applicationAmount: 0,
    processingFee: 0,
    compensationFundContribution: 0,
    surcharge: 0,
    totalAmount: 0,
    invoiceToEmail: '',
    spinner: true,
    error: undefined,
    isErrored: false,
    productDescription: '',
    paymentMethod: '',
    cardType: '',
    cardLast4Digits: '',
    totalAmountIncludesGst: false,
}