import React from "react";
//import "@servicensw/form/index.scss"

import { addSize, Form, Props, error } from "./Form"

export const Checkbox = ({
    value,
    setValue,
    validationMessage,
    size = "",
    fieldName,
    onClick = (event:MouseEvent) => {},
    ...other
}: Props & {onClick?: (event:any) => void }) => {
    const fieldType = <input type="checkbox" id={fieldName} name={fieldName} className={`${error(validationMessage)} ${addSize(size)}`} value={value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)} onClick={onClick} />;
    return (<Form fieldType={fieldType} fieldName={fieldName} validationMessage={validationMessage} suffixLabel={true} expandClass="form__checkbox" {...other} />);
};
