import Types from "AppTypes";
import { CANCEL_PAYMENT, RETRIEVE_CANCELLED_DATA_SUCCESS_ACTION, CANCELLED_PAYMENT_ERROR } from "./constants";
import { CancelledModel, initialState } from "./model";

export const cancelledReducer = (state: CancelledModel = initialState, action: Types.RootAction): CancelledModel => {
    switch (action.type) {
        case CANCEL_PAYMENT:
            return {
                ...state,
                paymentReference: action.payload,
                isInitialised: true,
            }
        case RETRIEVE_CANCELLED_DATA_SUCCESS_ACTION:
            return {
                ...state,
                isAnotherPayment: true,
            }
        case CANCELLED_PAYMENT_ERROR:
            return {
                ...state,
                isAnotherPayment: true,
            }
        default:
            return state;
    }
};
