import * as React from "react";
import "@servicensw/notice/index.scss"

type Props = {
    title: string,
    type?: "" | "Info" | "Error" | "Warning" | "Success" | "Plain",
    message?: string,
    children?: any,
}

export const Notice = ({
    title,
    type = "",
    message = "",
    children
}: Props) => {
    const typeMap: { [id: string]: string } = {
        "": "",
        "Info": "notice--info",
        "Error": "notice--error",
        "Warning": "notice--warning",
        "Success": "notice--success",
        "Plain": "notice--plain"
    }
    const titleMessage = title + " " + message;
    return (
        <div className={`notice ${typeMap[type]}`} aria-live="polite">
            <div className={"notice__title"}>
                {titleMessage}
            </div>
            {children}
        </div>
    );
}