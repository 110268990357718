import Types from "AppTypes";
import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { mergeMap, filter, map, catchError } from "rxjs/operators";
import { isOfType } from "typesafe-actions";
import { HOME_INITIALISE_DROP_DOWNS_ACTION } from "./constants";
import { SUBMIT_PAYMENT } from "./constants";
import { payerErrorAction, initialiseFormSuccessAction, initialiseFormErrorAction } from "./actions"
import { submitPaymentCompleteAction } from "./actions";
//todo: is there value in having an action (showSpinnerAction) that does not alter state?
//import { showSpinnerAction } from "./actions";
//import { startWith, endWith } from "rxjs/operators";

// See: https://github.com/mitsuruog/react-redux-observable-typescript-sample/blob/master/src/epics/WeatherEpic.ts

export const initialiseHomeDropDownsEpic: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> =
    (action$, state$, dependency) => {
        return action$.pipe(
            filter(isOfType(HOME_INITIALISE_DROP_DOWNS_ACTION)),

            mergeMap(action =>
                from(dependency.applicationFormsListApi())
                    .pipe(map(initialiseFormSuccessAction), catchError((error) => of(initialiseFormErrorAction(error))))
                //.pipe(startWith(showSpinnerAction(true)))
                //.pipe(endWith(showSpinnerAction(false)))
            )
        );
    };

//payer epic
export const submitEpic: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> =
    (action$, state$, dependency) => {
        return action$.pipe(
            filter(isOfType(SUBMIT_PAYMENT)),

            mergeMap(action =>
                from(dependency.submitApplicationApi(state$.value.app))
                    .pipe(map(submitPaymentCompleteAction), catchError((error) => of(payerErrorAction(error))))
                //.pipe(startWith(showSpinnerAction(true)))
                //.pipe(endWith(showSpinnerAction(false)))
            )
        );
    };
