// Models.
export type VerificationModel = {
    emailAddress: string,
    correlationId: string | undefined,
    verificationCode: string,
    verified: boolean,
}

export type PayerDetailModel = {
    applicantFirstName: string,
    applicantMiddleName: string,
    applicantLastName: string,
    companyName: string,
    isCompany: string,
    payerFullName: string,
    address: string,
    contact: VerificationModel,
    validationErrors: Map<string, string>,
    hasValidated: boolean,
    redirectUrl: string,
    submitted: boolean,
    isErrored: boolean,
}

export type HomeModel = {
    applicationDropdownValues: FormDetailsModel,
    agency: string,
    applicationNumber: string,
    formType: string,
    amountForApplication: string,//form input will be a string
    quantityForApplication: string,//form input will be a string
    additionalDetails: string,
    formInitialised: boolean,
    homeValidationErrors: Map<string, string>,
    hasValidated: boolean,
    payer: PayerDetailModel,
    isAmountForApplicationUpdateActionSuccess: boolean
    isAgencyResetSucess : boolean
}

// Model Initialisors.
export const initialStatePayer: PayerDetailModel = {
    applicantFirstName: '',
    applicantMiddleName: '',
    applicantLastName: '',
    companyName: '',
    isCompany: "",
    payerFullName: "",
    address: "",
    contact: {
        emailAddress: "",
        correlationId: undefined,
        verificationCode: "",
        verified: false,
    },
    validationErrors: new Map<string, string>(),
    hasValidated: false,
    redirectUrl: "",
    submitted: false,
    isErrored: false
}

export const initialStateHome: HomeModel = {
    applicationDropdownValues: {
        formDetails: [{
            formNumber: "",
            description: "",
            subAgency: "",
            agencyName: "",
            priceListUrl: "",
            fee: 0
        }],
        spinner: true,
    },
    applicationNumber: '',
    agency: '',
    formType: '',
    amountForApplication: "0",
    quantityForApplication: "1",
    additionalDetails: '',
    formInitialised: false,
    homeValidationErrors: new Map<string, string>(),
    hasValidated: false,
    payer: initialStatePayer,
    isAmountForApplicationUpdateActionSuccess: false,
    isAgencyResetSucess : true
}

export type FormDetailModel = {
    formNumber: string,
    description: string,
    subAgency: string,
    agencyName: string,
    priceListUrl: string,
    fee: number
}

export type FormDetailsModel = {
    formDetails: FormDetailModel[],
    spinner: boolean,
}

export type ApplicationError = {
    field: string,
    error: string,
}

export const applicationErrorInit: ApplicationError = {
    field: "",
    error: "",
}

export type ApplicationErrorCollection = {
    errors?: ApplicationError[],
}

export type ResponseModel = {
    redirectUrl: string,
}

export const responseModelInit: ResponseModel = {
    redirectUrl: "",
}

export type ApplicationModel = {
    home: HomeModel,
}

export type PaymentRequestModel = {
    applicationNumber: string;
    firstName: string;
    middleName: string;
    lastName: string;
    companyName: string;
    additionalDetails: string;
    payerFullName: string;
    payerEmailAddress: string;
    payerEmailAddressVerificationCode: string;
    amount: number;
}
