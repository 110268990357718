import React from "react";
import { useRef, useEffect } from "react";
//import "@servicensw/form/index.scss"

import { Form, Props, error, addSize, getFieldNameHelpText } from "./Form"
type TextareaProps = Props & {
    setFocus?: boolean;
};

export const Textarea = ({
    value,
    setValue,
    validationMessage,
    size = "",
    fieldName,
    setFocus = false,
    ...other
}: TextareaProps) => {
    const textareaInput = useRef<HTMLTextAreaElement | null>(null);
    useEffect(() => {
        if (textareaInput && setFocus) {
            textareaInput!.current!.focus();
        }
    }, [setFocus]);

    const fieldType = <textarea id={fieldName} className={`form__textarea ${error(validationMessage)} ${addSize(size)}`}
        value={value} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)}
        aria-describedby={getFieldNameHelpText(fieldName)} ref={textareaInput}
    />;
    return (<Form fieldType={fieldType} fieldName={fieldName} validationMessage={validationMessage} {...other} />);
};
