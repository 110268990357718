import { FormDetailsModel } from "../features/home/model";
import { tFetch, tResponse } from "../utils/apiUtils";

const applicationFormsListApi = () => {
    var resp = tFetch(`${(window as any).location.origin}/api/MetaData`,
        {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(handleCallback);
    return resp;
};

function handleCallback(response: tResponse): any {
    const apiResult = response.ok;

    const result = new Promise((resolve, reject) => {
        if (apiResult) {
            return response.json<FormDetailsModel>().then((r) => resolve(r));

        }

        const errors = [{
            field: "applicationNumber",
            error: "Sorry, we cannot get the 'Application Form Number' selection at the moment, please try again later",
        }];

        reject(errors);
    });
    return result;
}

export { applicationFormsListApi };