import Types from "AppTypes";
import { HomeModel, initialStateHome, ApplicationError } from "./model"
import {
    HOME_UPDATE_DETAIL_ACTION, HOME_UPDATE_AMOUNT_FOR_APPLICATION_ACTION, VALIDATE_HOME_FORM, HOME_INITIALISE_DROP_DOWNS_ACTION, HOME_INITIALISE_DROP_DOWNS_SUCCESS_ACTION, HOME_INITIALISE_DROP_DOWNS_ERROR_ACTION,
    PAYER_DETAIL_UPDATE_ACTION, SUBMIT_PAYMENT, SUBMIT_PAYMENT_COMPLETE, PAYER_ERROR, VALIDATE_PAYERDETAILS_FORM, HOME_INITIALISE_FORM_FEE_ACTION, RESET_AMOUNT_FOR_APPLICATION_UPDATE_ACTION_SUCCESS_ACTION, AGENCY_RESET_ACTION_SUCCESS_ACTION
} from "./constants";
import { RETRIEVE_CANCELLED_DATA_SUCCESS_ACTION } from "../cancelled/constants";
import { RETRIEVE_RECEIPT_DATA_SUCCESS_ACTION } from "../completion/constants";

export const homeReducer = (state: HomeModel = initialStateHome, action: Types.RootAction): HomeModel => {
    // Shared operation for make another payment
    const rehydrateState = (payload: any) =>
    {
        return {
            ...state,
            ...payload,
            amountForApplication: payload.amount.toString(),
            payer: {
                ...state.payer,
                applicantFirstName: payload.firstName,
                applicantMiddleName: payload.middleName,
                applicantLastName: payload.lastName,
                companyName: payload.companyName,
                payerFullName: payload.payerFullName,
                isCompany: payload.companyName ? "true" : "false",
                contact: {
                    ...state.payer.contact,
                    emailAddress: payload.payerEmailAddress,
                },
            },
        }
    }
    switch (action.type) {
        case HOME_UPDATE_DETAIL_ACTION:
            return {
                ...state,
                payer: {
                    ...state.payer,
                    contact: {
                        ...state.payer.contact
                    },
                    isCompany: action.payload.payer.isCompany
                },
                ...action.payload as {},
                applicationDropdownValues: {...state.applicationDropdownValues}
            };
        case HOME_UPDATE_AMOUNT_FOR_APPLICATION_ACTION:  
            return {
                ...state,
                ...action.payload as {}
            };
        case HOME_INITIALISE_FORM_FEE_ACTION:
            return {
                ...state,
                ...action.payload as {},
                isAmountForApplicationUpdateActionSuccess: true
            };
        case RESET_AMOUNT_FOR_APPLICATION_UPDATE_ACTION_SUCCESS_ACTION:
            return {
                ...state,
                isAmountForApplicationUpdateActionSuccess : false
            }
        case AGENCY_RESET_ACTION_SUCCESS_ACTION:
            return {
                ...state,
                isAgencyResetSucess: false
            }
        case VALIDATE_HOME_FORM:
            //crude client side validation goes here
            const model = action.payload;
            const validationDictionary: Map<string, string> = new Map<string, string>();

            if (model.payer.isCompany === "")
                validationDictionary.set("isCompany",
                    "Please indicate if this application is for an individual or company");

            if (model.applicationNumber === "") validationDictionary.set("applicationNumber", "'Application Form Number' is required");
            if (model.applicationNumber.length > 100) validationDictionary.set("applicationNumber", "'Application Form Number' needs to be less than 100 characters");
            if (model.additionalDetails.length > 500) validationDictionary.set("additionalDetails", "'Additional details' needs to be less than 500 characters");

            const amountForApplicationNumber = Number(model.amountForApplication);
            const amountIsNotANumber = Number.isNaN(amountForApplicationNumber);
            if (amountIsNotANumber) {
                //messages relating to not being a number
                validationDictionary.set("amountForApplication", "'Application Fee' is required. Please enter a number.");
                if (model.amountForApplication.length > 40) validationDictionary.set("amountForApplication", "'Application Fee' is required. Please enter a number. Did you type all of that in?");
                if (model.amountForApplication.includes("$")) validationDictionary.set("amountForApplication", "Please do not use '$' for 'Application Fee'. Please enter an 'Application Fee' in dollars to the nearest cent.");
                if (model.amountForApplication.includes(",")) validationDictionary.set("amountForApplication", "Please do not use a comma separator for thousands (,) in 'Application Fee'");
            }
            if (!amountIsNotANumber) {
                //messages relating to being a number with errors
                if (model.amountForApplication === "" || amountForApplicationNumber === 0 || amountIsNotANumber) {
                    validationDictionary.set("amountForApplication", "'Application Fee' is required");
                }
                if (amountForApplicationNumber < 0) {
                    validationDictionary.set("amountForApplication", "'Application Fee' must be greater than '0'.");
                }
                if (amountForApplicationNumber > 1000000) {
                    validationDictionary.set("amountForApplication", "'Application Fee' must be less than one million dollars");
                }
                const hasPartialCentsCheck = (amountToCheck: number): boolean => {
                    return amountToCheck !== parseFloat(amountToCheck.toFixed(2));
                };
                const hasPartialCents = hasPartialCentsCheck(amountForApplicationNumber);
                if (hasPartialCents) validationDictionary.set("amountForApplication", "Please enter an 'Application Fee' in dollars to the nearest cent");
                if (model.amountForApplication.length > 40) {
                    validationDictionary.set("amountForApplication", "'Application Fee' must be less than one million dollars");
                    if (model.amountForApplication.includes(".") && hasPartialCents) {
                        validationDictionary.set("amountForApplication", "Please enter an 'Application Fee' in dollars to the nearest cent");
                    }
                }
            }

            return {
                ...state,
                homeValidationErrors: validationDictionary,
                hasValidated: true
            };
        case HOME_INITIALISE_DROP_DOWNS_ACTION:
            return {
                ...state,
                formInitialised: true
            };
        case HOME_INITIALISE_DROP_DOWNS_SUCCESS_ACTION:
            //turn off the spinner and add the returned form details to the state
            return {
                ...state,
                applicationDropdownValues: {
                    formDetails: action.payload.formDetails,
                    spinner: false,
                }
            };
        case HOME_INITIALISE_DROP_DOWNS_ERROR_ACTION:
            const errorDictionary: Map<string, string> = new Map<string, string>();

            action.payload.forEach((validation: ApplicationError) => {
                errorDictionary.set(validation.field, validation.error);
            });

            return {
                ...state,
                applicationDropdownValues: {
                    formDetails: state.applicationDropdownValues.formDetails,
                    spinner: false,
                },
                homeValidationErrors: errorDictionary,
                hasValidated: true
            };
        case PAYER_DETAIL_UPDATE_ACTION:
            return {
                ...state, payer: {
                    ...state.payer,
                    ...action.payload,
                    contact: { ...action.payload.contact },
                }
            };
        case SUBMIT_PAYMENT:
            return {
                ...state,
                payer: {
                    ...state.payer,
                    ...action.payload.home.payer,
                    contact: action.payload.home.payer.contact,
                    submitted: true,
                    isErrored: false,
                }
            };
        case SUBMIT_PAYMENT_COMPLETE:
            return {
                ...state,
                payer: {
                    ...state.payer,
                    redirectUrl: action.payload.redirectUrl,
                    submitted: true,
                }
            };
        case PAYER_ERROR:
            const errorValidationDictionary: Map<string, string> = new Map<string, string>();

            action.payload.forEach((validation: ApplicationError) => {
                errorValidationDictionary.set(validation.field, validation.error);
            });

            return {
                ...state,
                payer: {
                    ...state.payer,
                    contact: { ...state.payer.contact },
                    validationErrors: errorValidationDictionary,
                    submitted: false,
                    isErrored: true,
                },
            };
        case VALIDATE_PAYERDETAILS_FORM:
            //crude client side validation goes here
            const model2 = action.payload;
            const validationDictionary2: Map<string, string> = new Map<string, string>();
            if (model2.isCompany === "true") {
                if (model2.companyName === "") validationDictionary2.set("companyName", "'Company name' is required");
                if (model2.companyName.length > 200) validationDictionary2.set("companyName", "'Company name' needs to be less than 200 characters");
            } else {
                if (model2.applicantFirstName === "") validationDictionary2.set("firstName", "'First name' is required");
                if (model2.applicantFirstName.length > 100) validationDictionary2.set("firstName", "'First name' needs to be less than 100 characters");
                if (model2.applicantMiddleName.length > 100) validationDictionary2.set("middleName", "'Middle name' needs to be less than 100 characters");
                if (model2.applicantLastName === "") validationDictionary2.set("lastName", "'Last name' is required");
                if (model2.applicantLastName.length > 100) validationDictionary2.set("lastName", "'Last name' needs to be less than 100 characters");
            }
            if (model2.payerFullName === "") validationDictionary2.set("PayerFullName", "'Payer full name' is required");
            if (model2.payerFullName.length > 500) validationDictionary2.set("PayerFullName", "'Payer full name' needs to be less than 500 characters");
            if (model2.contact.emailAddress === "") validationDictionary2.set("PayerEmailAddress", "'Email address' is required");
            if (model2.contact.emailAddress.length > 500) validationDictionary2.set("PayerEmailAddress", "'Email address' needs to be less than 500 characters");

            return {
                ...state,
                payer: {
                    ...state.payer,
                    validationErrors: validationDictionary2,
                    hasValidated: true,
                    submitted: false,
                    isErrored: validationDictionary2.size > 0,
                }
            };
        case RETRIEVE_CANCELLED_DATA_SUCCESS_ACTION:
            return rehydrateState(action.payload);
        case RETRIEVE_RECEIPT_DATA_SUCCESS_ACTION:
            return rehydrateState(action.payload);
        default:
            return state;
    }
};
