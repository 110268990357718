import Types from "AppTypes";
import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { mergeMap, filter, map, catchError } from "rxjs/operators";
import { isOfType } from "typesafe-actions";
import { CANCEL_PAYMENT, RETRIEVE_CANCELLED_DATA_ACTION } from "./constants";
import { cancelPaymentReturnAction, retrieveCancelledDataSuccessAction, cancelledErrorAction, cancelledPaymentErrorAction } from "./actions";
//todo: is there value in having an action (showSpinnerAction) that does not alter state?

// See: https://github.com/mitsuruog/react-redux-observable-typescript-sample/blob/master/src/epics/WeatherEpic.ts

export const initialiseCancelEpic: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> =
    (action$, state$, dependency) => {
        return action$.pipe(
            filter(isOfType(CANCEL_PAYMENT)),
            mergeMap(action =>
                from(dependency.cancelPaymentApi(state$.value.app.cancelled.paymentReference))
                .pipe(map(cancelPaymentReturnAction), catchError((error) => of(cancelledErrorAction(error))))
            )
        );
    };

export const paymentCancelledDetailsEpic: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> =
    (action$, state$, dependency) => {
        return action$.pipe(
            filter(isOfType(RETRIEVE_CANCELLED_DATA_ACTION)),

            mergeMap(action =>
                from(dependency.paymentDetailsApi(action.payload))
                .pipe(map(retrieveCancelledDataSuccessAction), catchError((error) => of(cancelledPaymentErrorAction(error))))
            )
        );
    };
