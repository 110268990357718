import React from 'react';
import PropTypes from 'prop-types';
import {LoaderContainer, LoaderImage, LoaderMessage} from './Loader.styled';

const Loader = ({content = 'Loading...'}) => (
    <LoaderContainer>
        <LoaderImage/>
        <LoaderMessage>{content}<br/></LoaderMessage>
    </LoaderContainer>
);

Loader.propTypes = {
    content: PropTypes.string,
};

export default Loader;