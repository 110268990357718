import { ResponseModel, CompleteError } from "../features/completion/model";
import { tFetch, tResponse } from "../utils/apiUtils";

const paymentDetailsApi = (paymentReference: string) => {
    var resp = tFetch(`${(window as any).location.origin}/api/PaymentDetails/${paymentReference}`,
        {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(handleCallback);
    return resp;
};

function handleCallback(response: tResponse): any {
    const apiResult = response.ok;

    const result = new Promise((resolve, reject) => {
        if (apiResult) {
            return response.json<ResponseModel>().then((r) => resolve(r));

        } else {
            //todo: check if reject needs to be called or returned like resolve above.
            //return response.json<ApplicationError[]>().then(err => {
            return response.text().then(err => {
                    reject({
                        returnType: response.status,
                        returnMessage: err
                    } as CompleteError);
                }
            );
        }
    });
    return result;
}

export { paymentDetailsApi };