import * as React from "react";
import { useState } from "react";

import { Button, Input, Select, Notice, Textarea, Fieldset, Checkbox, Checkboxes, Radio, Radios, Breadcrumbs, Breadcrumb, InlineNav, NavItem } from "../components/service/Index";

const style = {
    margin: "10px",
};
const StyleGuide = () => {
    const [value, setValue] = useState(""); 
    const [maskedvalue, maskedSetValue] = useState(""); 

    return (
        <div id="styles" style={style}>
            <div id="Headings">
                <h1>Heading H1</h1>
                <h2>Heading H2</h2>
                <h3>Heading H3</h3>
                <h4>Heading H4</h4>
                <h5>Heading H5</h5>
                <h6>Heading H6</h6>
            </div>
            <div id="buttons">
                <h1>Buttons</h1>
                <h2>Styles</h2>
                <Button>Primary</Button>
                <Button isDisabled={true}>Primary Disabled</Button>
                <Button isExternal={true}>Primary External</Button>
                <br/>
                <Button type="secondary">Secondary</Button>
                <Button type="secondary" isDisabled={true}>Secondary Disabled</Button>
                <Button type="secondary" isExternal={true}>Secondary External</Button>
                <br/>
                <Button type="tertiary">Tertiary</Button>
                <Button type="tertiary" isDisabled={true}>Tertiary Disabled</Button>
                <Button type="tertiary" isExternal={true}>Tertiary External</Button>
                <br/>
                <Button type="white">White</Button>
                <Button type="white" isDisabled={true}>White Disabled</Button>
                <Button type="white" isExternal={true}>White External</Button>
                <br/>
                <Button type="link">Link</Button>
                <br/>
                <Button type="arrow">Arrow</Button>
                <br/>
                <Button type="link" isDisabled={true}>Link Disabled</Button>
                <br/>
                <Button type="link" isExternal={true}>Link External</Button>
                <br/>
                <h2>Sizes</h2>
                <Button size="small">Button Small</Button>
                <Button size="xlarge">Button xLarge</Button>
                <Button size="xxlarge">Button xxLarge</Button>
                <Button size="short">Button Short</Button>
                <br/>
                <h2>Badges</h2>
                <Button isBadge={true} type="secondary">Badge</Button>
            </div>
            <h1>Form (fields/text)</h1>
            <div id="Input">
                <h2>Input</h2>
                <Input fieldName="afield" label="Form field" helpText="A standard form field." value={value} setValue={setValue}/>
                <Input fieldName="afield2" label="Error Form Field" helpText="A field with an error." validationMessage="Oops, 'tis bad she's gonna blow" value={value} setValue={setValue}/>
                <Input fieldName="afield3" label="Small form field" helpText="A small field." size="small" value={value} setValue={setValue} />
                <Input fieldName="maskedField" label="Date masked field" helpText="This field has a mask" mask="99/99/9999" value={maskedvalue} setValue={maskedSetValue} />
            </div>

            <div id="Select">
                <h2>Select</h2>

                <Select fieldName="afield" label="Form field" helpText="A standard form field." value={value} setValue={setValue}>
                    <option>Option 1</option>
                    <option>Option 2</option>
                    <option>Option 3</option>
                    <option>Option 4</option>
                </Select>

                <Select fieldName="afield" label="Form field Validation" helpText="A standard form field with validation." validationMessage="Oops, 'tis bad she's gonna blow" value={value} setValue={setValue}>
                    <option>Option 1</option>
                    <option>Option 2</option>
                    <option>Option 3</option>
                    <option>Option 4</option>
                </Select>

                <Select fieldName="afield" label="Form field small" helpText="A small form field." size="small" value={value} setValue={setValue}>
                    <option>Option 1</option>
                    <option>Option 2</option>
                    <option>Option 3</option>
                    <option>Option 4</option>
                </Select>
            </div>

            <div id="Textarea">
                <h2>Textarea</h2>
                <Textarea fieldName="textarea" label="Textarea field" helpText="A standard textarea field." value={value} setValue={setValue} />
                <Textarea fieldName="textareaErr" label="Error Textarea Field" helpText="A textarea field with an error." validationMessage="Oops, 'tis bad she's gonna blow" value={value} setValue={setValue} />
                <Textarea fieldName="textareaSmall" label="Small Textarea field" helpText="A small textarea field." size="small" value={value} setValue={setValue} />
            </div>

            <div id="Fieldset">
                <h2>Fieldset</h2>
                <Fieldset legend="Allows grouping of form elements" helpText="basis for grouped controls">
                    <Input fieldName="joined1" label="Joined input 1" helpText="Field within a form grouping element" value={value} setValue={setValue} />
                    <Input fieldName="joined2" label="Joined input 2" helpText="Field within a form grouping element" value={value} setValue={setValue} />
                    <Input fieldName="joined3" label="Joined input 3" helpText="Field within a form grouping element" value={value} setValue={setValue} />
                </Fieldset>
                <Fieldset legend="A grouping with an error" helpText="basis for grouped controls" validationMessage="Error can belong to group, or both">
                    <Input fieldName="joined1" label="Joined input 1" helpText="Field within a form grouping element" value={value} setValue={setValue} />
                    <Input fieldName="joined2" label="Joined input 2" helpText="Field within a form grouping element" value={value} setValue={setValue} validationMessage="Error can belong to child" />
                    <Input fieldName="joined3" label="Joined input 3" helpText="Field within a form grouping element" value={value} setValue={setValue} />
                </Fieldset>
            </div>

            <div id="Checkboxes">
                <h2>Checkboxes</h2>
                <h3>Singular</h3>
                <Checkbox fieldName="checkboxsingle" label="Single Checkbox" helpText="Checkbox outside group" value={value} setValue={setValue} />
                <Checkbox fieldName="errcheckboxsingle" label="Single Checkbox Erred" helpText="Erred Checkbox outside group" validationMessage="Checkbox with a problem" value={value} setValue={setValue} />
                <h3>Grouped</h3>
                <Checkboxes legend="Grouped Checkboxes" helpText="A group of checkboxes">
                    <Checkbox fieldName="groupedcheck1" label="Grouped Checkbox 1" helpText="Checkbox1" value={value} setValue={setValue} />
                    <Checkbox fieldName="groupedcheck2" label="Grouped Checkbox 2" helpText="Checkbox2" value={value} setValue={setValue} />
                    <Checkbox fieldName="groupedcheck3" label="Grouped Checkbox 3" helpText="Checkbox3" value={value} setValue={setValue} />
                </Checkboxes>
                <Checkboxes legend="Erred Grouped Checkboxes" helpText="A group of erred checkboxes" validationMessage="Failed Validation Message">
                    <Checkbox fieldName="errgroupedcheck1" label="Grouped Erred Checkbox 1" helpText="Checkbox1" validationMessage=" " value={value} setValue={setValue} />
                    <Checkbox fieldName="errgroupedcheck2" label="Grouped Erred Checkbox 2" helpText="Checkbox2" value={value} setValue={setValue} />
                    <Checkbox fieldName="errgroupedcheck3" label="Grouped Erred Checkbox 3" helpText="Checkbox3" value={value} setValue={setValue} />
                </Checkboxes>
            </div>

            <div id="Radios">
                <h2>Radios</h2>
                <Radios legend="A group of radio controls" helpText="example:">
                    <Radio fieldName="radios" label="Radio Control 1" helpText="Video killed the radio star" value={value} setValue={setValue} />
                    <Radio fieldName="radios" label="Radio Control 2" helpText="Video killed the radio star" value={value} setValue={setValue} />
                    <Radio fieldName="radios" label="Radio Control 3" helpText="Video killed the radio star" value={value} setValue={setValue} />
                </Radios>
                <Radios legend="An erred group of radio controls" helpText="example:" validationMessage="Failed condition">
                    <Radio fieldName="radiosErred" label="Radio Control 1" helpText="Video killed the radio star" validationMessage=" " value={value} setValue={setValue} />
                    <Radio fieldName="radiosErred" label="Radio Control 2" helpText="Video killed the radio star" value={value} setValue={setValue} />
                    <Radio fieldName="radiosErred" label="Radio Control 3" helpText="Video killed the radio star" value={value} setValue={setValue} />
                </Radios>
                <Radios legend="An inline group of radio controls" inline={true}>
                    <Radio fieldName="inlineRadios" label="Yes" helpText="Video killed the radio star" value={value} setValue={setValue} />
                    <Radio fieldName="inlineRadios" label="No" helpText="Video killed the radio star" value={value} setValue={setValue} />
                    <Radio fieldName="inlineRadios" label="Maybe" helpText="Video killed the radio star" value={value} setValue={setValue} />
                </Radios>
                <Radios legend="An erred inline group of radio controls" validationMessage="Failed condition" inline={true}>
                    <Radio fieldName="inlineRadiosErred" label="Yes" helpText="Video killed the radio star" value={value} setValue={setValue} />
                    <Radio fieldName="inlineRadiosErred" label="No" helpText="Video killed the radio star" value={value} setValue={setValue} />
                    <Radio fieldName="inlineRadiosErred" label="Maybe" helpText="Video killed the radio star" validationMessage=" " value={value} setValue={setValue} />
                </Radios>
            </div>

            <div id="Notice">
                <h2>Notice</h2>
                <Notice title="A Notice">
                    This notice contains:
                    <ul>
                        <li>Iconified message</li>
                    </ul>
                </Notice>
                <Notice title="An Info Notice" type="Info">
                    This notice contains:
                    <ul>
                        <li>Iconified message</li>
                    </ul>
                </Notice>
                <Notice title="An Error Notice" type="Error">
                    This notice contains:
                    <ul>
                        <li>Iconified message</li>
                        <li>Stylised output</li>
                    </ul>
                </Notice>
                <Notice title="A Warning Notice" type="Warning">
                    This notice contains:
                    <ul>
                        <li>Iconified message</li>
                        <li>Stylised output</li>
                    </ul>
                </Notice>
                <Notice title="A Success Notice" type="Success">
                    This notice contains:
                    <ul>
                        <li>Iconified message</li>
                        <li>Stylised output</li>
                    </ul>
                </Notice>
                <Notice title="A Plain Notice" type="Plain">
                    This notice contains:
                    <ul>
                        <li>Nothing Special</li>
                    </ul>
                </Notice>
            </div>

            <div id="Breadcrumbs">
                <h2>Breadcrumbs</h2>
                <Breadcrumbs ariaLabel="breadcrumbs">
                    <Breadcrumb label="Home" onClick={() => { window.alert("Home clicked"); }} />
                    <Breadcrumb label="Top level page" onClick={() => { window.alert("Top level page clicked"); }} />
                    <Breadcrumb label="Previous page" navPrevious={true} onClick={() => { window.alert("Previous clicked"); }} />
                </Breadcrumbs>
                <Breadcrumbs ariaLabel="breadcrumbs">
                    <Breadcrumb label="Backwards link" navBack={true} onClick={() => { window.alert("Back clicked"); }} />
                </Breadcrumbs>
            </div>

            <div id="Inline Navigation">
                <h2>Inline Navigation</h2>
                <InlineNav ariaLabel="Inline Navigation">
                    <NavItem label="Nav Page 1" onClick={() => { window.alert("Nav Page 1"); }} />
                    <NavItem label="Nav Page 2" onClick={() => { window.alert("Nav Page 2"); }} />
                </InlineNav>
            </div>
        </div>
    );
}


export default StyleGuide;
