import { action } from "typesafe-actions";
import {
    HOME_UPDATE_DETAIL_ACTION,
    HOME_UPDATE_AMOUNT_FOR_APPLICATION_ACTION,
    VALIDATE_HOME_FORM,
    HOME_INITIALISE_DROP_DOWNS_ACTION,
    HOME_INITIALISE_DROP_DOWNS_SUCCESS_ACTION,
    HOME_INITIALISE_DROP_DOWNS_ERROR_ACTION,
    HOME_INITIALISE_FORM_FEE_ACTION,
    SUBMIT_PAYMENT,
    SUBMIT_PAYMENT_COMPLETE,
    PAYER_ERROR,
    SHOW_SPINNER,
    VALIDATE_PAYERDETAILS_FORM,
    PAYER_DETAIL_UPDATE_ACTION,
    RESET_AMOUNT_FOR_APPLICATION_UPDATE_ACTION_SUCCESS_ACTION,
    AGENCY_RESET_ACTION_SUCCESS_ACTION,
} from "./constants";

import { FormDetailsModel, HomeModel } from "./model";
import { ApplicationError } from "./model";
import { PayerDetailModel, ApplicationModel, ResponseModel } from "./model";

export const homeUpdateAction = (model: HomeModel) => action(HOME_UPDATE_DETAIL_ACTION, model);

export const homeUpdateAmountForApplicationAction = (model: { amountForApplication: string}) => action(HOME_UPDATE_AMOUNT_FOR_APPLICATION_ACTION, model);

export const validateHomeFormAction = (model: HomeModel) => action(VALIDATE_HOME_FORM, model);

export const initialiseFormAction = () => action(HOME_INITIALISE_DROP_DOWNS_ACTION);

export const initialiseFormSuccessAction = (model: FormDetailsModel) => action(HOME_INITIALISE_DROP_DOWNS_SUCCESS_ACTION, model);

export const initialiseFormErrorAction = (model: ApplicationError[]) => action(HOME_INITIALISE_DROP_DOWNS_ERROR_ACTION, model);

export const payerErrorAction = (error: ApplicationError[]) => action(PAYER_ERROR, error);

export const submitPaymentAction = (model: ApplicationModel) => action(SUBMIT_PAYMENT, model);

export const submitPaymentCompleteAction = (model: ResponseModel) => action(SUBMIT_PAYMENT_COMPLETE, model);

export const showSpinnerAction = (show: boolean) => action(SHOW_SPINNER, show);

export const validatePayerFormAction = (model: PayerDetailModel) => action(VALIDATE_PAYERDETAILS_FORM, model);

export const payerDetailUpdateAction = (model: PayerDetailModel) => action(PAYER_DETAIL_UPDATE_ACTION, model);

export const initialiseFormFeeAction = (model: { applicationNumber: string; amountForApplication: string }) => action(HOME_INITIALISE_FORM_FEE_ACTION, model);

export const resetAmountForApplicationUpdateActionSuccessAction = () => action(RESET_AMOUNT_FOR_APPLICATION_UPDATE_ACTION_SUCCESS_ACTION);

export const agencyResetActionSuccessAction = () => action(AGENCY_RESET_ACTION_SUCCESS_ACTION);
