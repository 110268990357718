import Types from "AppTypes";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { createUseStyles } from "react-jss"
import { Button } from "../components/service/Index";

import { resendReceiptAction, retrieveReceiptDataAction } from "../features/completion/actions"

import ComponentLoader from "../components/loader/ComponentLoader.js";

const mapStateToProps = (state: Types.RootState) => ({
    needsContext: state.app.completion.needsContext,
    correlationId: state.app.completion.correlationId,
    receiptId: state.app.completion.receiptId,
    date: state.app.completion.date,
    invoiceToName: state.app.completion.invoiceToName,
    applicationAmount: state.app.completion.applicationAmount,
    processingFee: state.app.completion.processingFee,
    compensationFundContribution: state.app.completion.compensationFundContribution,
    surcharge: state.app.completion.surcharge,
    totalAmount: state.app.completion.totalAmount,
    invoiceToEmail: state.app.completion.invoiceToEmail,
    spinner: state.app.completion.spinner,
    error: state.app.completion.error,
    isErrored: state.app.completion.isErrored,
    productDescription: state.app.completion.productDescription,
    paymentMethod: state.app.completion.paymentMethod,
    cardType: state.app.completion.cardType,
    cardLast4Digits: state.app.completion.cardLast4Digits,
    totalAmountIncludesGst: state.app.completion.totalAmountIncludesGst,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        resendReceiptAction,
        retrieveReceiptDataAction
    }, dispatch);

const styles = createUseStyles({
    applicationStyle: {
        margin: {
            left: "5%",
            right: "5%"
        }
    },
    buttonBar: {
        marginTop: "70px",
        display: "flex",
        '& .button--primary': {
            marginRight: "160px"
        }
    },
    largeDataBlock: {
        width: "75%"
    },
    nextOffset: {
        margin: {
            top: "70px",
            bottom: "70px"
        }
    },
    largePad: {
        paddingTop: "160px"
    },
    inlineContainer: {
        display: "inline-block"
    },
    splitContainer: {
        display: "flex"
    },
    splitSegment1: {
        width: "25%",
        minWidth: "5rem",
    },
    splitSegment2: {
        width: "50%",
        minWidth: "8rem",
    },
    splitSegment3: {
        width: "22.5%", textAlign: "right", marginLeft: "auto",
        minWidth: "5rem",
    },
    splitSegment4: {
        width: "70%",
    },
    splitSegment5: {
        width: "10%",
    },
    splitSegment6: {
        width: "20%",
    },
    invoiceTo: {
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-all",
        hyphens: "auto"
    },

});

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const Completion = (props: Props) => {
    const classes = styles();

    const { needsContext, date, invoiceToName, applicationAmount, surcharge, totalAmount, spinner, isErrored, error, retrieveReceiptDataAction, productDescription, paymentMethod, cardType, cardLast4Digits, totalAmountIncludesGst } = props;
    //const {correlationId, processingFee, compensationFundContribution, invoiceToEmail} = props;

    const paymentReference = document.location.search.replace("?paymentReference=", "").replace("?completionReference=", "");
    const displayDate = date === "" ? "" : new Date(date).toLocaleString("en-AU").replace(", ", " at ");

    function printPage() {
        window.print();
    }

    useEffect(() => {
        document.title = "BRD Payments Portal - Step 3: Payment successful";
        if (needsContext && paymentReference)
            retrieveReceiptDataAction(paymentReference);
    }, [needsContext, paymentReference, retrieveReceiptDataAction]);

    return !isErrored ? (
        <div className={`${classes.applicationStyle}`}>
            <h2>Your payment is successful</h2>
            <hr />
            <h3>Tax Invoice</h3>
            <div className={classes.inlineContainer}>
                <div className={classes.splitContainer}>
                    <div className={classes.splitSegment1}>Receipt ID:</div>
                    <div className={classes.splitSegment2}><b>{paymentReference}</b></div>
                </div>
                <div className={classes.splitContainer}>
                    <div className={classes.splitSegment1}>Date:</div>
                    <div className={classes.splitSegment2}><b>{displayDate}</b></div>
                </div>
                <div className={classes.splitContainer}>
                    <div className={classes.splitSegment1}>Invoice to:</div>
                    <div className={`${classes.splitSegment2} ${classes.invoiceTo}`}><b>{invoiceToName}</b></div>
                </div>
                <br />
                <div className={classes.splitContainer}>
                    <div className={classes.splitSegment1}>Payment Method:</div>
                    <div className={classes.splitSegment2}><b>{paymentMethod}</b></div>
                </div>
                {paymentMethod.toUpperCase() === "CARD" ? (
                    <>
                        <div className={classes.splitContainer}>
                            <div className={classes.splitSegment1}>Card Type:</div>
                            <div className={classes.splitSegment2}><b>{cardType}</b></div>
                        </div>
                        <div className={classes.splitContainer}>
                            <div className={classes.splitSegment1}>Card Last 4 Digits:</div>
                            <div className={classes.splitSegment2}><b>{cardLast4Digits}</b></div>
                        </div>
                    </>) : <><div></div><div></div></>}
                <br className={classes.largePad} />
                <div className={classes.largeDataBlock}>
                    <table>
                        <tbody>
                            <tr>
                                <th className={classes.splitSegment4}>Description</th>
                                {totalAmountIncludesGst ? (
                                    null
                                ) : (
                                    <th className={classes.splitSegment5} style={{ textAlign: "right" }}>GST</th>
                                )}
                                <th className={classes.splitSegment6} style={{ textAlign: "right" }}>{totalAmountIncludesGst ? <>Total&nbsp;including&nbsp;GST</> : <>Total</>}</th>
                            </tr>
                            <tr style={{borderBottom: "solid 1px"}}>
                                <td>{productDescription}</td>
                                {totalAmountIncludesGst ? (
                                    null
                                ) : (
                                    <td style={{ textAlign: "right" }}>$0.00</td>
                                )}
                                <td style={{ textAlign: "right", paddingBottom: "35px" }}><b>${(applicationAmount || 0).toFixed(2)}</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="summary-header">
                        Surcharge including GST
                    </div>
                    <div style={{ textAlign: "right", display: "inline-block", width: "20%", minWidth: "4rem" }}>
                        <b>${surcharge.toFixed(2)}</b>
                    </div>
                    <div className="summary-header">
                        Total paid
                    </div>
                    <div style={{ textAlign: "right", display: "inline-block", width: "20%", minWidth: "4rem" }}>
                        <b>${totalAmount.toFixed(2)}</b>
                    </div>
                </div>
                <br className={classes.largePad} />
                <br className={classes.largePad} />
            </div>
            <div className="addressInformation">
                Service NSW<br />
                ABN: <b>37&nbsp;552 837&nbsp;401</b><br />
                McKell Building<br />
                2-24 Rawson Place<br />
                Sydney, 2000
            </div>
            <br className={classes.largePad} />
            <div id="button-bar" className={`${classes.nextOffset} ${classes.buttonBar}`}>
                <Button onClick={() => window.open("/api/pdfReceipt/" + paymentReference)}>Download as PDF</Button>
                <Button type="secondary" onClick={printPage}>Print</Button>
                <Button type="secondary" href="/">Make another payment</Button>
            </div>

            <ComponentLoader active={spinner} />
        </div>
    ) : error && error.returnType === 404 ? (
        <div className={`${classes.applicationStyle}`}>
            <h2>404 - Not Found</h2>
            <hr />
            The requested item could not be found.
        </div >
    ) : error ? (
        <div className={`${classes.applicationStyle}`}>
            <h2>{ error.returnType }</h2>
            <hr />
            { error.returnMessage }
        </div>
    ) : (
        <div className={`${classes.applicationStyle}`}>
            <h2>An error has occurred</h2>
            <hr />
            We apologise for the inconvenience.
        </div>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Completion);
