import React from 'react';
import PropTypes from 'prop-types';
import {DimmerContainer} from './Dimmer.styled';

const Dimmer = ({active, fullPage, children}) => active ? (
    <DimmerContainer fullPage={fullPage}>
        {children}
    </DimmerContainer>
) : <></>;

Dimmer.propTypes = {
    active: PropTypes.bool,
    fullPage: PropTypes.bool,
};

Dimmer.defaultProps = {
    active: false,
    fullPage: true
};

export default Dimmer;