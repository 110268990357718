import * as React from "react";
//import "@servicensw/form/index.scss"

export type FieldsetProps = {
    options?: boolean,
    inline?: boolean,
    legend?: string,
    children?: any,
    helpText?: string,
    validationMessage?: string,
}

export const Fieldset = ({
    options = false,
    inline = false,
    legend,
    children,
    helpText,
    validationMessage
}: FieldsetProps) => {
    const validator = () => validationMessage ? <p className="form__error" aria-live="polite">{validationMessage}</p> : <p aria-live="polite"></p>;
    const helpTag = () => helpText ? <p className="form__help">{helpText}</p> : null;
    var appliedClasses = "form__item";
    appliedClasses += options ? " form__options" : "";
    appliedClasses += inline ? " form--options-inline" : "";
    return (
        <fieldset className={appliedClasses}>
            <legend>{legend}</legend>
            {helpTag()}
            {children}
            {validator()}
        </fieldset>
    );
};