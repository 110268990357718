import React from 'react';
import PropTypes from 'prop-types';
import Dimmer from './Dimmer';
import Loader from './Loader';

const ComponentLoader = ({active}) => (
    <Dimmer active={active}>
        <Loader/>
    </Dimmer>
);

ComponentLoader.propTypes = {
    active: PropTypes.bool.isRequired,
};

export default ComponentLoader;
