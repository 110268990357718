import Types from "AppTypes";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { createUseStyles } from "react-jss";

import { cancelPaymentAction, retrieveCancelledDataAction } from "../features/cancelled/actions"

import { Notice, Button } from "../components/service/Index";

const mapStateToProps = (state: Types.RootState) => ({
    paymentReference: state.app.cancelled.paymentReference,
    isAnotherPayment: state.app.cancelled.isAnotherPayment,
    isInitialised: state.app.cancelled.isInitialised,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        cancelPaymentAction,
        retrieveCancelledDataAction,
    }, dispatch);

const styles = createUseStyles({
    applicationStyle: {
        margin: {
            left: "5%",
            right: "5%"
        }
    },
    buttonBar: {
        marginTop: "70px",
        display: "flex",
        '& a': {
            marginRight: "160px"
        }
    },
    splitContainer: {
        display: "flex"
    },
    splitSegment: {
        width: "25%",
    },
});

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const Cancelled = (props: Props) => {
    const classes = styles();

    const searchPaymentReference = document.location.search
        .replace("?paymentReference=", "")
        .replace("?cancelledReference=", "")
        .replace("?completionReference=", "");

    const { paymentReference, isInitialised, isAnotherPayment } = props;

    const makeAnotherPayment = () => {
        props.retrieveCancelledDataAction(searchPaymentReference);
    }

    // we should dispatch this action on button press, We are not using watchers
    useEffect(() => {
        document.title = "BRD Payments Portal - Step 3: Payment cancelled";
        if (!isInitialised)
            props.cancelPaymentAction(searchPaymentReference);
    }, [isInitialised, searchPaymentReference, paymentReference, props]);

    return isAnotherPayment ?
        (<Redirect to='/' />): (
        <div className={`${classes.applicationStyle}`}>
            <h2>Your payment has been cancelled</h2>
            <hr />
            <Notice title="" type="Error">
                Your payment has been cancelled.
            </Notice>
            <div className={classes.buttonBar}>
                <Button onClick={ makeAnotherPayment }>Make another payment</Button>
            </div>
        </div>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Cancelled);
