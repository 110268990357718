import * as React from "react";
import "@servicensw/navigation/index.scss";
//import "@servicensw/navigation";

type Props = {
    children?: any;
    ariaLabel: string;
}

export const InlineNav = ({
    ariaLabel,
    children
}: Props) => {
    return (
        <nav className="inline-nav" role="navigation" aria-label={ariaLabel}>
            <ul>
                {children}
            </ul>
        </nav>
    );
};