import { combineReducers } from "redux";
import { homeReducer } from "../features/home/reducer";
import { completionReducer } from "../features/completion/reducer";
import { cancelledReducer } from "../features/cancelled/reducer";

const rootReducer = combineReducers({
    app: combineReducers({
        home: homeReducer,
        completion: completionReducer,
        cancelled: cancelledReducer,
    }),
});

export default rootReducer;