/*eslint no-var: "error"*/

import Types from "AppTypes";
import * as React from "react";
import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";

import { Model } from "../features/home/index";
import { HomeModel, FormDetailsModel } from "../features/home/model";

import { homeUpdateAction, homeUpdateAmountForApplicationAction, validateHomeFormAction, initialiseFormAction, initialiseFormFeeAction, resetAmountForApplicationUpdateActionSuccessAction, agencyResetActionSuccessAction } from "../features/home/actions";
import { Input, Button, Notice, Select, Textarea, Radios, Radio } from "../components/service/Index";

import { payerDetailUpdateAction, validatePayerFormAction } from "../features/home/actions";
import { submitPaymentAction } from "../features/home/actions";
import { ApplicationModel } from "../features/home/model";
import { PayerDetailModel } from "../features/home/model";

import ComponentLoader from "../components/loader/ComponentLoader.js";
import { formBehaviour } from "../features/home/form-extensions";


const styles = createUseStyles({
    formOffset: {
        maxWidth: "1200px",
        padding: {
            left: "5%",
            right: "5%"
        }
    },
    nextOffset: {
        margin: {
            top: "70px",
            bottom: "70px"
        }
    },
    splitContainer: {
        display: "flex"
    },
    splitSegment: {
        width: "50%",
        '& .notice': {
            marginTop: "67px"
        }
    },
    applicationStyle: {
        margin: {
            left: "5%",
            right: "5%"
        }
    },
    buttonBar: {
        marginTop: "70px",
        display: "flex",
        '& .button--secondary': {
            marginRight: "160px"
        }
    },
    right: {
        float: "right"
    }
});

const mapStateToProps = (state: Types.RootState, filter: any) => ({
    applicationDropdownValues: state.app.home.applicationDropdownValues,
    applicationNumber: state.app.home.applicationNumber,
    agency: state.app.home.agency || (filter.match ? filter.match.params.agency || '' : ''),
    amountForApplication: state.app.home.amountForApplication,
    applicantFirstName: state.app.home.payer.applicantFirstName,
    applicantMiddleName: state.app.home.payer.applicantMiddleName,
    applicantLastName: state.app.home.payer.applicantLastName,
    companyName: state.app.home.payer.companyName,
    isCompany: state.app.home.payer.isCompany,
    additionalDetails: state.app.home.additionalDetails,
    initialised: state.app.home.formInitialised,
    homeValidationErrors: state.app.home.homeValidationErrors,
    homeHasValidated: state.app.home.hasValidated,
    payerFullName: state.app.home.payer.payerFullName,
    emailAddress: state.app.home.payer.contact.emailAddress,
    payerValidationErrors: state.app.home.payer.validationErrors,
    redirectUrl: state.app.home.payer.redirectUrl,
    payerHasValidated: state.app.home.payer.hasValidated,
    submitted: state.app.home.payer.submitted,
    isErrored: state.app.home.payer.isErrored,
    isAmountForApplicationUpdateActionSuccess: state.app.home.isAmountForApplicationUpdateActionSuccess,
    isAgencyResetSucess: state.app.home.isAgencyResetSucess
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        homeUpdateAction,
        initialiseFormAction,
        validateHomeFormAction,
        payerDetailUpdateAction,
        validatePayerFormAction,
        submitPaymentAction,
        initialiseFormFeeAction,
        homeUpdateAmountForApplicationAction,
        resetAmountForApplicationUpdateActionSuccessAction,
        agencyResetActionSuccessAction,
    }, dispatch);

const displayErrorsAfterSubmit = (errorList: Map<string, string>, isErrored: boolean) => {
    if (isErrored) {
        let errorsParagraph = "";
        errorList.forEach((value, keys) => {
            errorsParagraph += value + "; ";
        });

        let plural = "s";
        let areIs = "are";
        if (errorList.size === 1) {
            plural = "";
            areIs = "is";
        }
        return <Notice type="Error" title={`Validation summary: `} message={`There ${areIs} ${errorList.size} error${plural}`}>{errorsParagraph}</Notice>;
    }
    return <div></div>;
}

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

let options: any = [];
const applicationFormOptions = (applicationDropdownValues: FormDetailsModel, agency: string) => {
    if (applicationDropdownValues.formDetails[0].description !== "") {
        options = [];
        options.push(<option key="applicationSelectPrompt" value="...">Please Select...</option>);
        for (const opt in applicationDropdownValues.formDetails) {
            if (agency === "" || agency === "..." ||
                applicationDropdownValues.formDetails[opt].agencyName === agency) {
                const option = applicationDropdownValues.formDetails[opt];
                options.push(<option key={`application${option.formNumber}`} value={option.formNumber}>{option.formNumber} - {
                    option.description}</option>);
            }
        }
    }
    return options;
};

let agencyName: any = [];
let agencyOptions: any = [];
const agencyFormOptions = (agencyDropdownValues: FormDetailsModel) => {
    if (agencyOptions.length === 0 && agencyDropdownValues.formDetails[0].description !== "") {
        agencyOptions = [];
        agencyOptions.push(<option key="agencySelectPrompt" value="...">Please Select...</option>);
        for (const opt in agencyDropdownValues.formDetails) {
            if (!agencyName.includes(agencyDropdownValues.formDetails[opt].agencyName)) {
                const option = agencyDropdownValues.formDetails[opt];
                agencyName.push(agencyDropdownValues.formDetails[opt].agencyName);
                agencyOptions.push(<option key={`application${option.agencyName}`} value={option.agencyName}>{
                    option.agencyName}</option>);
            }
        }
    }
    return agencyOptions;
}

let formCostsLink = (<div aria-live="polite"></div>);
let formExtension: JSX.Element[] = [ (<div aria-live='polite' key="initial1"></div>) ];

const Home = (props: Props) => {
    const classes = styles();

    const {
        initialiseFormAction,
        homeValidationErrors,
        homeHasValidated,
        applicationDropdownValues,
        initialised,
        homeUpdateAction,
        validateHomeFormAction,
        submitted,
        isErrored,
        payerDetailUpdateAction,
        validatePayerFormAction,
        submitPaymentAction,
        payerHasValidated,
        payerValidationErrors,
        redirectUrl,
        initialiseFormFeeAction,
        homeUpdateAmountForApplicationAction,
        isAmountForApplicationUpdateActionSuccess,
        resetAmountForApplicationUpdateActionSuccessAction,
        isAgencyResetSucess,
        agencyResetActionSuccessAction,
    } = props;

    const [payerFullName, setPayerFullName] = useState(props.payerFullName);
    const [emailAddress, setEmailAddress] = useState(props.emailAddress);

    const payerDetailsStore = useSelector<Types.RootState, PayerDetailModel>(state => state.app.home.payer);

    const [isNewPage, setIsNewPage] = useState(true);
    const [isNewCompanyStep, setIsNewCompanyStep] = useState(false);
    const [isNewIndividualStep, setIsNewIndividualStep] = useState(false);

    const homePage = "HOME_PAGE";
    const payerPage = "PAYER_PAGE";

    const serverValidationAmountIndex = "Amount";
    const amountForApplicationIndex = "amountForApplication";
    const isCompanyIndex = "isCompany";
    const additionalDetailsIndex = "additionalDetails";
    const applicationNumberIndex = "applicationNumber";
    const agencyIndex = "agency";
    const defaultIndex = "default";
    type HomeFocusIndex = "isCompany" | "additionalDetails" | "amountForApplication" | "applicationNumber" | "agency" | "default";

    const payerEmailAddressIndex = "emailAddress";
    const serverValidationPayerEmailAddressIndex = "PayerEmailAddress";
    const payerFullNameIndex = "payerFullName";
    const serverValidationPayerFullNameIndex = "PayerFullName";
    const payerLastNameIndex = "lastName";
    const payerMiddleNameIndex = "middleName";
    const payerFirstNameIndex = "firstName";
    const payerCompanyNameIndex = "companyName";
    type PayerFocusIndex = "emailAddress" | "payerFullName" | "lastName" | "middleName" | "firstName" | "companyName" |  "default";

    const defaultPayerFocus = {
        [payerEmailAddressIndex]: false,
        [payerFullNameIndex]: false,
        [payerLastNameIndex]: false,
        [payerMiddleNameIndex]: false,
        [payerFirstNameIndex]: false,
        [payerCompanyNameIndex]: false,
        [defaultIndex]: true
    };
    const [payerFocus, setPayerFocus] = useState(defaultPayerFocus);
    const resetPayerFocus = () => setPayerFocus(_ => defaultPayerFocus);

    const validatePayer = () => {
        window.scrollTo(0, 0);
        resetPayerFocus();
        validatePayerFormAction(payerDetailsStore);
    };

    useEffect(() => {
        initialiseFormAction();
    }, [initialiseFormAction]);//run once, let the redux promise determine success or failure

    const defaultHomeFocus = {
        [isCompanyIndex]: false,
        [additionalDetailsIndex]: false,
        [amountForApplicationIndex]: false,
        [applicationNumberIndex]: false,
        [agencyIndex]: false,
        [defaultIndex]: true
    };
    const [homeFocus, setHomeFocus] = useState(defaultHomeFocus);
    const resetHomeFocus = () => setHomeFocus(_ => defaultHomeFocus);

    const homeStore = useSelector<Types.RootState, HomeModel>(state => state.app.home);
    const validateHome = () => {
        window.scrollTo(0, 0);
        resetHomeFocus();
        validateHomeFormAction(homeStore);
    };

    const [applicationNumber, setApplicationNumber] = useState(props.applicationNumber);
    const [agency, setAgency] = useState(props.agency);
    const [amountForApplication, setAmountForApplication] = useState(props.amountForApplication);
    const [firstName, setFirstName] = useState(props.applicantFirstName);
    const [middleName, setMiddleName] = useState(props.applicantMiddleName);
    const [lastName, setLastName] = useState(props.applicantLastName);
    const [companyName, setCompanyName] = useState(props.companyName);
    const [isCompany, setIsCompany] = useState(props.isCompany);
    const [additionalDetails, setAdditionalDetails] = useState(props.additionalDetails);

    useEffect(() => {        
        const sameState = (objectToCheck: any): boolean => {
            if (objectToCheck.applicationNumber === props.applicationNumber &&
                objectToCheck.agency === props.agency &&
                objectToCheck.additionalDetails === props.additionalDetails) {
                return true;
            }
            return false;
        };
        const homeValidationErrorsTransfer = new Map<string, string>();
        if (!(payerValidationErrors.get(serverValidationAmountIndex) === null || payerValidationErrors.get(serverValidationAmountIndex) === undefined)) {
            homeValidationErrorsTransfer.set(amountForApplicationIndex, payerValidationErrors.get(serverValidationAmountIndex) || "");
        }
        const formValues = {
            applicationNumber,
            additionalDetails,
            agency,
            formInitialised: true,
            hasValidated: false,
            homeValidationErrors: homeValidationErrorsTransfer,
            isAgencyResetSucess
        };

        if (initialised && !sameState(formValues)) {
            if (formValues.agency !== props.agency) {
                formValues.isAgencyResetSucess = true;
            }
            console.log("calling homeUpdateAction in useEffect without application amount")
            homeUpdateAction(Object.assign(Model.initialStateHome, formValues));        
            }

        if (applicationDropdownValues) {
            // Apply form behaviour extension
            formExtension = formBehaviour(applicationNumber, setApplicationNumber);

            // Apply price list hyperlink
            const formWithPriceListUrl = (applicationDropdownValues as FormDetailsModel).formDetails.find((form) => form.formNumber === applicationNumber && form.priceListUrl);
            if (formWithPriceListUrl) {
                formCostsLink = (<div aria-live="polite" style={{ margin: "-1rem 0 1.6rem 0" }}><a href={formWithPriceListUrl.priceListUrl} target="_blank" rel="noopener noreferrer" aria-label="Where can I find the amount to pay? Opens in new window">where can I find the application fee amount?</a></div>);
            } else {
                if (applicationNumber) {
                    formCostsLink = (<div style={{ fontSize: ".875rem", color: "#646974", margin: "-1rem 0 1.6rem 0"}} aria-live="polite">For payment please refer to the fees displayed on your application. If none listed please use the fee schedule link displayed on your application form under 'Application Fees'</div>);
                }
                else {
                    formCostsLink = (<div aria-live="polite"></div>);
                }
            }
        }
    }, [applicationNumber, additionalDetails, isCompany, initialised, homeUpdateAction,
        props.applicationNumber, props.isCompany, props.additionalDetails, payerValidationErrors, applicationDropdownValues, agency, props.agency, isAgencyResetSucess]);
    
    useEffect(() => {
        if (isAgencyResetSucess) {
            setApplicationNumber('');
            agencyResetActionSuccessAction();
        }
    }, [isAgencyResetSucess, agencyResetActionSuccessAction])

    useEffect(() => {
        if (initialised) {//&& props.isFormTypeChanged

            const applicationNumberChanged = applicationNumber !== props.applicationNumber;
            //const amountForApplicationChanged = amountForApplication !== props.amountForApplication && amountForApplication !== "0";
            const amountForApplicationChanged = amountForApplication !== props.amountForApplication;

            //if applicationNumber not changed, and amountForApplication changed, then the user is chosing their own amountForApplication so set a flag
            const userHasUpdatedAmountForApplication = !applicationNumberChanged && amountForApplicationChanged;
            //if applicationNumber changed then we want to overwrite the application amount
            if (applicationNumberChanged) {
                if (applicationNumber === '') {
                    setAmountForApplication('0');
                }
                else {
                    const formSelected = applicationDropdownValues.formDetails.find((form) => form.formNumber === applicationNumber);
                    let fee = '0';

                    //if the application number has changed, then we will always want to update the fee
                    if (formSelected) {
                        fee = formSelected.fee.toString();
                    }
                    //send action
                    console.log("initialiseFormFeeAction because drop down used: ", { applicationNumber, amountForApplication });

                    initialiseFormFeeAction({ applicationNumber, amountForApplication: fee });
                }
            }
            if (userHasUpdatedAmountForApplication && !applicationNumberChanged) {
                homeUpdateAmountForApplicationAction({ amountForApplication });
            }
        }

    }, [applicationNumber, amountForApplication, props.applicationNumber, props.amountForApplication, initialised, initialiseFormFeeAction, homeUpdateAmountForApplicationAction, applicationDropdownValues]);

    useEffect(() => {
        if (isAmountForApplicationUpdateActionSuccess) {
            setAmountForApplication(props.amountForApplication);
            resetAmountForApplicationUpdateActionSuccessAction();
        }
    }, [isAmountForApplicationUpdateActionSuccess, props.amountForApplication, resetAmountForApplicationUpdateActionSuccessAction])

    const [currentPage, setCurrentPage] = useState(homePage);
    const sizeHomeErrors = homeValidationErrors.size;

    useEffect(() => {
        //if customer has pressed next on first page and no validation errors, then show the second page
        if (sizeHomeErrors === 0 && homeHasValidated) {
            setCurrentPage(payerPage);
        }

        //https://www.w3.org/WAI/tutorials/forms/multi-page/#using-the-page-title
        if (currentPage === homePage) {
            document.title = "Step 1 of 2 - Application details - BRD Payments Portal";
            setIsNewPage(true);
            setIsNewCompanyStep(false);
            setIsNewIndividualStep(false);
        }
        else {
            document.title = "Step 2 of 2 - Applicant details - BRD Payments Portal";
            setIsNewPage(false);
            if (props.isCompany === "true") {
                setIsNewCompanyStep(true);
                setIsNewIndividualStep(false);
            } else {
                setIsNewCompanyStep(false);
                setIsNewIndividualStep(true);
            }
        }
    }, [homeHasValidated, currentPage, sizeHomeErrors, props.isCompany]);

    //start payer business logic
    const back = () => {
        window.scrollTo(0, 0);
        setCurrentPage(homePage);
        //todo: remove duplication with homeValidationErrorsTransfer
        const homeValidationErrorsTransfer2 = new Map<string, string>();
        if (!(payerValidationErrors.get(serverValidationAmountIndex) === null || payerValidationErrors.get(serverValidationAmountIndex) === undefined)) {
            homeValidationErrorsTransfer2.set(amountForApplicationIndex, payerValidationErrors.get(serverValidationAmountIndex) || "");
        }
        homeUpdateAction(Object.assign(Model.initialStateHome, {
            applicationNumber,
            amountForApplication,
            additionalDetails,
            formInitialised: true,
            hasValidated: false,
            homeValidationErrors: homeValidationErrorsTransfer2,
        }));
    };

    useEffect(() => {
        const payerSameState = (objectToCheck: any) => {
            if (objectToCheck.applicantFirstName === props.applicantFirstName &&
                objectToCheck.applicantMiddleName === props.applicantMiddleName &&
                objectToCheck.applicantLastName === props.applicantLastName &&
                objectToCheck.isCompany === props.isCompany &&
                objectToCheck.companyName === props.companyName &&
                objectToCheck.payerFullName === props.payerFullName &&
                objectToCheck.contact.emailAddress === props.emailAddress) {
                return true;
            }
            return false;
        };

        const payerFormValues = {
            applicantFirstName: firstName,
            applicantMiddleName: middleName,
            applicantLastName: lastName,
            isCompany: isCompany,
            companyName: companyName,
            payerFullName: payerFullName,
            contact: {
                emailAddress: emailAddress,
            },
        };
        if (!payerSameState(payerFormValues)) {
            payerDetailUpdateAction(Object.assign(Model.initialStatePayer, payerFormValues));
        };
    }, [payerDetailUpdateAction, firstName, middleName, lastName, companyName, isCompany, payerFullName, emailAddress,
        props.applicantFirstName, props.applicantMiddleName, props.applicantLastName, props.companyName, props.isCompany, props.payerFullName, props.emailAddress]);

    useEffect(() => {
        if (redirectUrl !== "") document.location.href = encodeURI(redirectUrl);
    }, [redirectUrl]);

    const { size } = payerValidationErrors;
    const applicationStore = useSelector<Types.RootState, ApplicationModel>(state => state.app);

    useEffect(() => {
        if (size === 0 && payerHasValidated && !submitted) {
            submitPaymentAction(applicationStore);
        }
    }, [submitPaymentAction, applicationStore, payerHasValidated, submitted, size]);
    //end payer business logic

    useEffect(() => {
        const constantHomeFocus = { [isCompanyIndex]: false, [additionalDetailsIndex]: false, [amountForApplicationIndex]: false, [applicationNumberIndex]: false, [agencyIndex]: false, [defaultIndex]: true };

        let homeFocusIndex: HomeFocusIndex = defaultIndex;
        if (homeValidationErrors.get(isCompanyIndex)) {
            homeFocusIndex = isCompanyIndex;
        }
        if (homeValidationErrors.get(additionalDetailsIndex)) {
            homeFocusIndex = additionalDetailsIndex;
        }
        if (homeValidationErrors.get(amountForApplicationIndex)) {
            homeFocusIndex = amountForApplicationIndex;
        }
        if (homeValidationErrors.get(applicationNumberIndex)) {
            homeFocusIndex = applicationNumberIndex;
        }
        if (homeValidationErrors.get(agencyIndex)) {
            homeFocusIndex = agencyIndex;
        }
        if (homeFocusIndex !== defaultIndex) {
            const newHomeFocus = { ...constantHomeFocus };
            newHomeFocus[homeFocusIndex] = true;
            setHomeFocus(_ => newHomeFocus);
        }
    }, [homeValidationErrors]);

    useEffect(() => {
        const constantPayerFocus = { [payerEmailAddressIndex]: false, [payerFullNameIndex]: false, [payerLastNameIndex]: false, [payerMiddleNameIndex]: false, [payerFirstNameIndex]: false, [payerCompanyNameIndex]: false, [defaultIndex]: true };

        let payerFocusIndex: PayerFocusIndex = defaultIndex;
        if (payerValidationErrors.get(serverValidationPayerEmailAddressIndex)) {
            payerFocusIndex = payerEmailAddressIndex;
        }
        if (payerValidationErrors.get(serverValidationPayerFullNameIndex)) {
            payerFocusIndex = payerFullNameIndex;
        }
        if (payerValidationErrors.get(payerLastNameIndex)) {
            payerFocusIndex = payerLastNameIndex;
        }
        if (payerValidationErrors.get(payerMiddleNameIndex)) {
            payerFocusIndex = payerMiddleNameIndex;
        }
        if (payerValidationErrors.get(payerFirstNameIndex)) {
            payerFocusIndex = payerFirstNameIndex;
        }
        if (payerValidationErrors.get(payerCompanyNameIndex)) {
            payerFocusIndex = payerCompanyNameIndex;
        }
        if (payerFocusIndex !== defaultIndex) {
            const newPayerFocus = { ...constantPayerFocus };
            newPayerFocus[payerFocusIndex] = true;
            setPayerFocus(_ => newPayerFocus);
        }
    }, [payerValidationErrors]);


    return currentPage === homePage ? (
        <div className={classes.formOffset}>
            Complete the information below based on the application you are submitting
            <br />
            <h2>Application details</h2>
            <hr />
            <Notice type="Info" title="Please ensure the details on this page match the details on your application. Inconsistent information may result in delays in processing your application." />
            <div id="validationErrorSummary" aria-live="polite" tabIndex={-1}>
                {displayErrorsAfterSubmit(homeValidationErrors, homeValidationErrors.size > 0)}
            </div>
            <Select fieldName={agencyIndex} label="Agency to issue payment to (optional)" value={agency} setFocus={homeFocus.agency} setStepFocus={isNewPage}
                setValue={setAgency} validationMessage={homeValidationErrors.get(agencyIndex)}>
                {agencyFormOptions(applicationDropdownValues)}
            </Select>
            <Select fieldName={applicationNumberIndex} label="Application Form Number" value={applicationNumber} setFocus={homeFocus.applicationNumber}
                setValue={setApplicationNumber} validationMessage={homeValidationErrors.get(applicationNumberIndex)}
            >{applicationFormOptions(applicationDropdownValues, agency)}</Select>
            <div aria-live="polite">
                {formExtension}
            </div>
            {/*<div className={classes.right}>*/}
            
            <Input fieldName={amountForApplicationIndex} label="Application Fee" value={amountForApplication} setFocus={homeFocus.amountForApplication}
                setValue={setAmountForApplication} validationMessage={homeValidationErrors.get(amountForApplicationIndex)} />
            <div>
                {formCostsLink}
            </div>
            <Textarea fieldName={additionalDetailsIndex} label="Additional Details (optional)"
                helpText="Provide any other details associated with this application. e.g. licence number, plant registration number, mobile number etc."
                size="small" value={additionalDetails} setValue={setAdditionalDetails} setFocus={homeFocus.additionalDetails}
                validationMessage={homeValidationErrors.get(additionalDetailsIndex)}></Textarea>
            <div>
                Is this an individual or company/entity application
                <Radios inline={true} validationMessage={homeValidationErrors.get(isCompanyIndex)}>
                    <Radio fieldName={isCompanyIndex} label="Individual" value={false} setValue={setIsCompany} checked={isCompany === "false"}
                        setFocus={homeFocus.isCompany} />
                    <Radio fieldName={isCompanyIndex} label="Company/Entity" value={true} setValue={setIsCompany} checked={isCompany === "true"} />
                </Radios>
            </div>
            <div className={classes.nextOffset}>
                <Button onClick={validateHome}>Next</Button>
            </div>
            <ComponentLoader active={applicationDropdownValues.spinner} />
        </div>
    ) : (
            <div className={`${classes.applicationStyle}`}>
                You need to provide your payer details and a valid email address in order to proceed with this application
                <h2>Applicant details</h2>
                <hr />
                <div id="validationErrorSummary" aria-live="polite" tabIndex={-1}>
                    {displayErrorsAfterSubmit(payerValidationErrors, isErrored)}
                </div>
                <div hidden={isCompany === "false"}>
                    <Input fieldName={payerCompanyNameIndex} label="Company name" value={companyName} setValue={setCompanyName} validationMessage={payerValidationErrors.get(payerCompanyNameIndex)}
                        setFocus={payerFocus.companyName} setStepFocus={isNewCompanyStep} />
                </div>
                <div hidden={isCompany === "true"}>
                    <Input fieldName={payerFirstNameIndex} label="First name" value={firstName} setValue={setFirstName} validationMessage={payerValidationErrors.get(payerFirstNameIndex)}
                        setFocus={payerFocus.firstName} setStepFocus={isNewIndividualStep} />
                    <Input fieldName={payerMiddleNameIndex} label="Middle name (optional)" value={middleName} setValue={setMiddleName} validationMessage={payerValidationErrors.get(payerMiddleNameIndex)}
                        setFocus={payerFocus.middleName} />
                    <Input fieldName={payerLastNameIndex} label="Last name" value={lastName} setValue={setLastName} validationMessage={payerValidationErrors.get(payerLastNameIndex)}
                        setFocus={payerFocus.lastName} />
                </div>
                <h2>Payer details</h2>
                <hr />
                <Input fieldName={payerFullNameIndex} label="Payer full name" value={payerFullName} setFocus={payerFocus.payerFullName}
                    setValue={setPayerFullName} validationMessage={payerValidationErrors.get(serverValidationPayerFullNameIndex)} />
                <Input fieldName={payerEmailAddressIndex} label="Email address" value={emailAddress} setFocus={payerFocus.emailAddress}
                    setValue={setEmailAddress} validationMessage={payerValidationErrors.get(serverValidationPayerEmailAddressIndex)} />
                <Notice type="Info" title="Note: On clicking next, you will be redirected to Service NSW payment portal to complete the payment transaction" />
                <div className={`${classes.nextOffset} ${classes.buttonBar}`}>
                    <Button type="secondary" onClick={back}>Back</Button>
                    <Button onClick={validatePayer} isDisabled={submitted}>Next</Button>
                </div>
                <ComponentLoader active={submitted} />
            </div>
        );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
