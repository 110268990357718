import * as React from "react";
import { Link } from "react-router-dom";
import "@servicensw/buttons/index.scss"

type Props = {
    children?: string,
    href?: string,
    isDisabled?: boolean,
    isExternal?: boolean,
    isBadge?: boolean,
    type?: "primary" | "secondary" | "tertiary" | "white" | "link" | "arrow",
    onClick?: () => void,
    size?: "small" | "xlarge" | "xxlarge" | "short" | "",
}

export const Button = ({
    href = "#",
    isDisabled = false,
    isExternal = false,
    isBadge = false,
    children = "&nbsp;",
    type = "primary",
    size = "", onClick }: Props) => {

    const disabled = isDisabled ? "is-disabled" : "";
    const external = isExternal ? "is-external" : "";
    const addLink = type === "arrow" ? "button--link" : "";
    const addSize = (size ? "button--" : "") + size;
    const addBadge = isBadge ? "badge" : "";

    return href !== "#" ? (
        <Link role="button" to={href} className={`button button--${type} ${addLink} ${disabled} ${external} ${addSize} ${addBadge}`}>
            {children}
        </Link>
    ) : (
            <button className={`button button--${type} ${addLink} ${disabled} ${external} ${addSize} ${addBadge}`} onClick={onClick}>{children}</button>
        );
};
