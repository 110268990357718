import React from "react";
import { useEffect, useState } from "react";
//import "@servicensw/form/index.scss";
import { addSize, Form, Props, error } from "./Form";
import InputMask from "react-input-mask";

type InputProp = Props & {
    mask?: string;
    maskChar?: string;
    setFocus?: boolean;
    setStepFocus?: boolean;
}

export const Input = ({
    value,
    setValue,
    validationMessage="",
    size = "",
    fieldName,
    mask = "",
    maskChar = "_",
    setFocus = false,
    setStepFocus = false,
    ...other
}: InputProp) => {
    const [currentRef, setCurrentRef] = useState<HTMLInputElement | null>(null);
    const [focusVisibleClass, setFocusVisibleClass] = useState("");
    useEffect(() => {
        if (setFocus && currentRef !== null) {
            currentRef!.focus();
            setFocusVisibleClass("focus-visible");
        }
    }, [setFocus, currentRef]);

    useEffect(() => {
        if (setStepFocus && currentRef !== null) {
            currentRef!.focus();
            setFocusVisibleClass("focus-visible");
        }
    }, [setStepFocus, currentRef]);

    function inputRefFunction(ref: any) {
        setCurrentRef(ref);
    }

    const fieldType = <InputMask inputRef={inputRefFunction} mask={mask} maskChar={maskChar} id={fieldName} className={`form__text ${error(validationMessage)} ${addSize(size)} ${focusVisibleClass}`} value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)} onBlur={() => setFocusVisibleClass("")} />;
    return (<Form fieldType={fieldType} fieldName={fieldName} validationMessage={validationMessage} {...other} />);
};
