import * as React from "react";
import { Checkbox } from "../../components/service/Index";

const resetEnhancements = () => {
    let buttons = document.querySelector('button');
    if (buttons)
        buttons.disabled = false;

    if (!(window as any).viewState)
        (window as any).viewState = {
            confirm: false,
        }
}

const verifyLicenseDuration = (key: string, valueSetter: any) => {
    const conditionallyDisableButton = () => {
        let storedConfirm = (window as any).viewState;
        let confirmationBox = document.getElementById("confirm");
        if (storedConfirm && confirmationBox && storedConfirm.confirm)
            (confirmationBox as HTMLInputElement).checked = true;
        if (confirmationBox && (confirmationBox as HTMLInputElement).checked) {
            let buttons = document.querySelector('button');
            if (buttons)
                buttons.disabled = false;
        } else {
            let buttons = document.querySelector('button');
            if (buttons)
                buttons.disabled = true;
        }
    }
    // Validator changes would require state changes and all the hell involved, denying access to the button seems easier.
    const click = (event: Event) => {
        let storedConfirm = (window as any).viewState;
        if (storedConfirm)
            if (storedConfirm.confirm)
                storedConfirm.confirm = false;
            else
                storedConfirm.confirm = true;
        conditionallyDisableButton();
    }

    conditionallyDisableButton();

    return (<div key={key}><Checkbox fieldName="confirm" label="I confirm that I have held a Tow Truck Driver certificate or Operator licence continuously for at least three consecutive years and am eligible for a multi year licence." value={true} setValue={(value) => { }} onClick={click} /><br/> <b>Note:</b> You must have held your <b>Tow Truck Driver certificate</b> or <b>Operator licence</b>  continuously for 3 years to be eligible to renew for 3 or 5 years. Reasons for non-continuity include your <b>Tow Truck Driver certificate</b> or <b>Operator Licence</b> being suspended, revoked or lapsed.  In addition for a <b>Tow Truck Driver certificate</b> you must also have no more than one drivers licence suspension in 2 years.</div>);
}

export const formBehaviour = (formNumber: string, valueSetter: any) => {
    resetEnhancements();
    type BehaviourDelegates = {
        formNumber: string,
        delegate: any,
    }
    const behaviourDelegates: BehaviourDelegates[] = [
        {
            formNumber: "04TTDR-3Y",
            delegate: verifyLicenseDuration
        },
        {
            formNumber: "04TTDR-5Y",
            delegate: verifyLicenseDuration
        },
        {
            formNumber: "16TTOP-3Y",
            delegate: verifyLicenseDuration
        },
        {
            formNumber: "16TTOP-5Y",
            delegate: verifyLicenseDuration
        }
    ];
    let index = 0;
    let returnItems: JSX.Element[] = [];
    let executionList = behaviourDelegates.filter((behaviour) => { return behaviour.formNumber === formNumber });
    executionList.forEach((behaviour) => { returnItems.push(behaviour.delegate("formExtension"+(index++), valueSetter)); });
    return returnItems;
}