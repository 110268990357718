import * as React from "react";
import { Link } from "react-router-dom";
import "@servicensw/tiles/dist/tiles.css";
import { createUseStyles } from "react-jss"

const styles = createUseStyles({
    cardStyle: {
        maxWidth: "340px",
        minWidth: "340px",
    },
});

type Props = {
    children?: any;
    label: string;
    href?: string;
    link?: string;
    onClick?: () => void;
}

export const MenuLinkBox = ({
    label,
    children,
    href = "",
    onClick = () => { },
    link = "",
}: Props) => {
    const classes = styles();
    return (
        <Link to={link} className={`tile tile--menu-box ${classes.cardStyle}`} href={href} onClick={onClick}>
            <div>
                <h3>{label}</h3>
                <p>{children}</p>
            </div>
        </Link>
    );
};

