import { ApplicationModel, PaymentRequestModel, ResponseModel, ApplicationErrorCollection, ApplicationError } from "../features/home/model";
import { tFetch, tResponse } from "../utils/apiUtils";

function flatten(applicationDetails: ApplicationModel): PaymentRequestModel {
    return {
        applicationNumber: applicationDetails.home.applicationNumber,
        firstName: applicationDetails.home.payer.applicantFirstName,
        middleName: applicationDetails.home.payer.applicantMiddleName,
        lastName: applicationDetails.home.payer.applicantLastName,
        companyName: applicationDetails.home.payer.companyName,
        additionalDetails: applicationDetails.home.additionalDetails,
        payerFullName: applicationDetails.home.payer.payerFullName,
        payerEmailAddress: applicationDetails.home.payer.contact.emailAddress,
        payerEmailAddressVerificationCode: applicationDetails.home.payer.contact.verificationCode,
        amount: Number(applicationDetails.home.amountForApplication),
    }
};

const submitApplicationApi = (applicationDetails: ApplicationModel) => {
    var resp = tFetch(`${(window as any).location.origin}/api/PaymentRequest`,
        {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(flatten(applicationDetails))
        })
        .then(handleCallback);
    return resp;
};

function handleCallback(response: tResponse): any {
    const apiResult = response.ok;

    const result = new Promise((resolve, reject) => {
        if (apiResult) {
            return response.json<ResponseModel>().then((r) => resolve(r));
        }

        if (response.status === 400) {
            return response.json<ApplicationErrorCollection>().then(responseErr => { reject(responseErr); });
        }

        const networkOrServerErr: ApplicationError[] = [{
            field: response.statusText,
            error: "We're sorry, your request hasn't been sent due to a network/server error. Please try again later"
        } as ApplicationError];

        return Promise.resolve<ApplicationError[]>(networkOrServerErr).then(err => { reject(err); });
    });

    return result;
}

export { submitApplicationApi };