import Types from "AppTypes";
import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { mergeMap, filter, map, catchError } from "rxjs/operators";
import { isOfType } from "typesafe-actions";
import { RETRIEVE_RECEIPT_DATA_ACTION } from "./constants";
import { retrieveReceiptDataSuccessAction, errorAction } from "./actions";


export const paymentDetailsEpic: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> =
    (action$, state$, dependency) => {
        return action$.pipe(
            filter(isOfType(RETRIEVE_RECEIPT_DATA_ACTION)),

            mergeMap(action =>
                from(dependency.paymentDetailsApi(action.payload))
                    .pipe(map(retrieveReceiptDataSuccessAction), catchError((error) => of(errorAction(error))))
            )
        );
    };


