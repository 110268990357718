import * as React from "react";
//import "@servicensw/form/index.scss"

export type Props = SharedProps & {
    size?: "small" | "xlarge" | "xxlarge" | "short" | "",
    setValue: (initialState: any | (() => any)) => any,
    value: any,
    children?: any,
}

type SharedProps = {
    fieldName: string,
    label: string,
    helpText?: string,
    validationMessage?: string,
}

type FormProps = SharedProps & {
    fieldType: any,
    expandClass?: string,
    suffixLabel?: boolean,
}

export const Form = ({
    suffixLabel = false,
    fieldName,
    label,
    helpText="",
    validationMessage,
    fieldType,
    expandClass = ""
}: FormProps) => {
    const validator = () => validationMessage ? <p id={getFieldNameError(fieldName)} className="form__error" aria-live="polite">{
        validationMessage}</p> : <p id={getFieldNameError(fieldName)} aria-live="polite"></p>;
    const help = () => helpText ? <p className="form__help" id={getFieldNameHelpText(fieldName)}>{helpText}</p>:null;

    return suffixLabel ? (
        <div className={`form__item ${expandClass}`}>
            {fieldType}
            <label htmlFor={fieldName}>{label}</label>
            {help()}
            {validator()}
        </div>
    ) : (
        <div className={`form__item ${expandClass}`}>
            <label htmlFor={fieldName}>{label}</label>
            {fieldType}
            {help()}
            {validator()}
        </div>
    );
};

export const getFieldNameError = (fieldName: string) => fieldName + "Error";
export const getFieldNameHelpText = (fieldName: string) => fieldName + "HelpText";
export const addSize = (size: string) => size ? `form--${size}` : "";
export const error = (validationMessage: string | undefined) => validationMessage ? "error" : "";