import Types from "AppTypes";
import * as React from "react";
import { connect } from "react-redux";
import { Header } from "../service/Header";

const mapStateToProps = (state: Types.RootState) => ({
});

type Props = ReturnType<typeof mapStateToProps> & {
    children: any,
}

const Layout = (props: Props) => (
    <div>
        <Header>Make payment for application</Header>
        <nav></nav>
        <section className="container">
            {props.children}
        </section>
        <article></article>
        <aside></aside>
        <footer></footer>
    </div>
);

export default connect(
    mapStateToProps,
    null
)(Layout)