import * as React from "react";
import store from "./store";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createUseStyles } from "react-jss";
import "@servicensw/base/dist/base.css";
import "@servicensw/base";

import Layout from "./components/shared/Layout";
import Home from "./routes/Home";
import StyleGuide from "./routes/StyleGuide";
import Completion from "./routes/Completion";
import Cancelled from "./routes/Cancelled";


// See: https://github.com/rojasmi1/cra-jss

const styles = createUseStyles({
    baseAppOverride: {
        '& .container': {
            maxWidth: "1200px",
            marginLeft: "auto",
            marginRight: "auto",
        },
        '& h3': {
            fontWeight: "400",
            borderBottom: "none",
            paddingBottom: "1rem",
            color: "#002664",
            marginBottom: 0,
        },
        '& hr': { display: "block", height: "0.125rem", border: "0", borderTop: "0.125rem solid #002664", margin: "0", padding: "0", },
    }
});

const App: React.FC = () => {
    const classes = styles();
    return (
        <div className={classes.baseAppOverride}>
            <Provider store={store}>
                <BrowserRouter>
                    <Layout>                        
                        <Switch>
                            <Route exact path="/styleguide" component={StyleGuide} />
                            <Route exact path="/completion" component={Completion} />
                            <Route exact path="/cancelled" component={Cancelled} />
                            <Route exact path="/:agency?" component={Home} />
                            <Route exact path="*" component={Home} />
                        </Switch>
                    </Layout>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

export default App;
