import * as React from "react";
//import "@servicensw/form/index.scss";

import { FieldsetProps, Fieldset } from "./Fieldset"

export const Checkboxes = ({
    legend,
    children,
    helpText,
    validationMessage,
    ...other
}: FieldsetProps) => {
    return (
        <Fieldset legend={legend} helpText={helpText} validationMessage={validationMessage} options={true} {...other}>
            <div className="form__checkboxes">
                {children}
            </div>
        </Fieldset>
    );
};