import { action } from "typesafe-actions";
import { ResponseModel } from "./model";
import { CANCEL_PAYMENT, CANCEL_RETURN, RETRIEVE_CANCELLED_DATA_ACTION, RETRIEVE_CANCELLED_DATA_SUCCESS_ACTION, CANCELLED_ERROR, CANCELLED_PAYMENT_ERROR } from "./constants";

export const cancelPaymentAction = (model: string) =>
    action(CANCEL_PAYMENT, model);

export const cancelPaymentReturnAction = (model: string) => action(CANCEL_RETURN, model);

export const retrieveCancelledDataAction = (paymentReference: string) => {
    return action(RETRIEVE_CANCELLED_DATA_ACTION, paymentReference);
}

export const retrieveCancelledDataSuccessAction = (model: ResponseModel) =>
    action(RETRIEVE_CANCELLED_DATA_SUCCESS_ACTION, model);

export const cancelledErrorAction = (error: any) => {
    return action(CANCELLED_ERROR, error);
}

export const cancelledPaymentErrorAction = (error: any) => {
    return action(CANCELLED_PAYMENT_ERROR, error);
}