import React from "react";
import { useRef, useEffect } from "react";
//import "@servicensw/form/index.scss"

import { addSize, Form, Props, error } from "./Form"
type RadioProps = Props & {
    checked?: boolean;
    setFocus?: boolean;
};
var controlNumber: number = 0;

export const Radio = ({
    value,
    setValue,
    validationMessage,
    checked = false,
    size = "",
    fieldName,
    setFocus = false,
    ...other
}: RadioProps) => {
    const radioInput = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (radioInput && setFocus) {
            radioInput!.current!.focus();
        }
    }, [setFocus]);

    const fieldType = <input type="radio" id={`${fieldName}${++controlNumber}`} name={fieldName} className={`${error(validationMessage)} ${addSize(size)}`} value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)} checked={checked} ref={radioInput} />;
    return (<Form fieldType={fieldType} fieldName={`${fieldName}${controlNumber}`} validationMessage={validationMessage} suffixLabel={true} {...other} />);
};
