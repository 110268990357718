import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

export const DimmerContainer = styled.div`
    position: ${props => props.fullPage ? 'fixed' : 'absolute'};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    opacity: .8;
`;

DimmerContainer.propTypes = {
    fullPage: PropTypes.bool
};