// Models.
export type CancelledModel = {
    paymentReference: string,
    isAnotherPayment: boolean,
    isInitialised: boolean,
}

export const initialState = {
    paymentReference: '',
    isAnotherPayment: false,
    isInitialised: false,
}

export type ResponseModel = {
    paymentReference: string,
    bankReference: string,
    firstName: string,
    middleName: string,
    lastName: string,
    companyName: string,
    payerFullName: string,
    amount: number,
    surcharge: number,
    surchargeGst: number,
    payerEmailAddress: string,
    completedAt: string,
    paymentMethod: string,
    cardType: string,
    cardLast4Digits: string,
    totalAmountIncludesGst: boolean,
}