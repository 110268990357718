import { RootAction, RootState, Services } from "AppTypes";
import { applyMiddleware, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { composeEnhancers } from "./utils";
import rootReducer from "./root-reducer";
import rootEpic from "./root-epic";
import services from "../services";

export const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState,
    Services
>({
    dependencies: services,
});

// configure middleware
const middleware = [epicMiddleware];

// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middleware));

// rehydrate state on app start
const initialState = {};

// create store
const store = createStore(rootReducer, initialState, enhancer);

epicMiddleware.run(rootEpic);

// export store singleton instance
export default store;
