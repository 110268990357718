import * as React from "react";
import "@servicensw/navigation/index.scss";

import { NavProps } from "./Navigation";

export const NavItem = ({
    label,
    onClick,
    href = "#"
}: NavProps) => {
    return (
        <li><a href={href} onClick={onClick}>{label}</a></li>
    );
}